import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Drawer } from '@popmenu/common-ui';
import { makeStyles } from '@material-ui/core/styles';

import { profileDrawerStyles } from './profile.styles';

import { closeProfileV2Modal } from '../../shared/ModalActions';

import ProfileContextProvider from '../../shared/ProfileContextProvider';
import ProfileHeader from './internal/ProfileHeader';
import ProfileContent from './internal/ProfileContent';

// Respective heading AH is present inside ProfileHeader, so disabling the rule is OK.
// eslint-disable-next-line regex/invalid
import { AHLevelProvider } from '../shared/AccessibleHeading';

const useStyles = makeStyles(profileDrawerStyles);

const ProfileV2Modal = () => {
  const classes = useStyles();
  const showProfileV2Modal = useSelector(state => state.modals.showProfileV2Modal);
  const dispatch = useDispatch();

  const closeProfileModal = () => {
    dispatch(closeProfileV2Modal());
  };

  return (
    <ProfileContextProvider>
      <Drawer
        anchor="right"
        open={showProfileV2Modal}
        onClose={() => closeProfileModal()}
        classes={classes}
      >
        <ProfileHeader />
        <AHLevelProvider>
          <ProfileContent />
        </AHLevelProvider>
      </Drawer>
    </ProfileContextProvider>
  );
};

export default ProfileV2Modal;
