import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box } from '@popmenu/common-ui';

import { ProfileContext } from '../../../shared/ProfileContextProvider';
import Loading from '../../../shared/Loading';
import ProfileNavigation from './ProfileNavigation';
import ProfilePageContentRegion from './ProfilePageContentRegion';
import { AH } from '../../shared/AccessibleHeading';

const ProfileContent = () => {
  const {
    activeUser,
    loading,
  } = useContext(ProfileContext);

  if (loading) {
    return <Loading />;
  }

  if (activeUser?.userId) {
    return (
      <Box display="flex" flexGrow={1}>
        <ProfileNavigation />
        <ProfilePageContentRegion />
      </Box>
    );
  }

  return (
    <AH typography variant="h6">
      <FormattedMessage
        id="profile.profile_unavailable"
        defaultMessage="Profile is Unavailable"
      />
    </AH>
  );
};

export default ProfileContent;
