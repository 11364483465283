import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useQuery } from '~/lazy_apollo/client';
import { compose } from '@shakacode/recompose';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { Avatar } from '@popmenu/common-ui';

import { Clock, CreditCard, Gift, Profile, Star, Tag } from '@popmenu/web-icons';

import { currentUserShape, withCurrentUser, withCurrentSession } from './CurrentSessionProvider';
import guestProfileUserQuery from '../libs/gql/queries/users/guestProfileUserQuery.gql';
import { withRestaurant } from '../utils/withRestaurant';
import { formatDate } from '../utils/time';

const INITIAL_PROFILE_STATE = {
  activeProfilePage: 'my_info',
  activeUser: {},
  loading: true,
  orderHistory: [],
  orderingOfferCodes: [],
  profileNavigationHeight: 70,
  profileNavigationWidth: 240,
  profilePages: {
    favorites: {
      display: true,
      hasCount: false,
      icon: Star,
      id: 'favorites',
      order: 3,
      title: 'Favorites',
    },
    gift_card: {
      display: true,
      hasCount: true,
      icon: Gift,
      id: 'gift_card',
      order: 2,
      profileCount: 'unusedGiftcards',
      title: 'Gift Cards',
    },
    my_info: {
      display: true,
      hasCount: false,
      icon: Avatar,
      id: 'my_info',
      order: 5,
      title: 'My Info',
    },
    my_profile: {
      display: false,
      id: 'my_profile',
      title: 'My Profile',
    },
    offers: {
      display: true,
      hasCount: true,
      icon: Tag,
      id: 'offers',
      order: 1,
      profileCount: 'unreadOffers',
      title: 'Offers',
    },
    order_history: {
      display: false,
      hasCount: false,
      icon: Clock,
      id: 'order_history',
      order: 4,
      title: 'Recent Orders',
    },
    payment: {
      display: false,
      hasCount: false,
      icon: CreditCard,
      id: 'payment',
      order: 6,
      title: 'Payment',
    },
  },
  restaurantLocations: [],
};

export const ProfileContext = React.createContext({
  ...INITIAL_PROFILE_STATE,
});

const ProfileContextProvider = ({ children, currentSession, currentUser, restaurant, width }) => {
  // will be one of offers, gift_card, favorites, order_history, my_info *as default first time, will be offers other times, and payment
  const [activeProfilePage, setActiveProfilePage] = useState(isWidthDown('md', width) ? 'my_profile' : 'my_info');
  const setProfilePage = useSelector(state => state.modals.setActiveProfilePage);
  const activeCartLocationSlug = useSelector(state => state.consumer.activeCartLocationSlug);

  useEffect(() => {
    if (setProfilePage) {
      setActiveProfilePage(setProfilePage);
    }
  }, [setProfilePage]);

  const showProfileV2Modal = useSelector(state => state.modals.showProfileV2Modal);

  const profilePages = useMemo(
    () => ({
      favorites: {
        display: true,
        hasCount: false,
        icon: Star,
        id: 'favorites',
        order: 3,
        title: 'Favorites',
      },
      gift_card: {
        display: true,
        hasCount: true,
        icon: Gift,
        id: 'gift_card',
        order: 2,
        profileCount: 'unusedGiftcards',
        title: 'Gift Cards',
      },
      my_info: {
        display: true,
        hasCount: false,
        icon: Profile,
        id: 'my_info',
        order: 5,
        title: 'My Info',
      },
      my_profile: {
        display: false,
        id: 'my_profile',
        title: 'My Profile',
      },
      offers: {
        display: true,
        hasCount: true,
        icon: Tag,
        id: 'offers',
        order: 1,
        profileCount: 'unreadOffers',
        title: 'Offers',
      },
      order_history: {
        display: restaurant.featureSetting.isOrderingEnabled,
        hasCount: false,
        icon: Clock,
        id: 'order_history',
        order: 4,
        title: 'Recent Orders',
      },
      payment: {
        display: false,
        hasCount: false,
        icon: CreditCard,
        id: 'payment',
        order: 6,
        title: 'Payment',
      },
    }), [
      restaurant.featureSetting.isOrderingEnabled,
    ],
  );

  const { data, loading } = useQuery(
    guestProfileUserQuery,
    {
      fetchPolicy: 'cache-and-network',
      skip: !showProfileV2Modal || !currentSession.user?.id,
      variables: {
        restaurantId: restaurant.id,
        userId: currentSession.user?.id,
      },
    },
  );

  const user = data?.guestProfileUser;
  const restaurantLocations = data?.restaurant?.locations;
  // provide restaurant default order url, or otherwise provide catering url if no default exists
  const orderPageUrl = data?.restaurant?.onlineOrderDefaultUrl || data?.restaurant?.onlineOrderCateringUrl;
  const renderOrderCartUrl = useCallback(
    (specifiedLocationSlug, useCatering) => {
      // if catering is specified, provide catering url, otherwise provide restaurant default
      const baseOrderUrl = useCatering ? data?.restaurant?.onlineOrderCateringUrl : orderPageUrl;

      // if location is specified, use specified location
      if (specifiedLocationSlug) {
        return `${baseOrderUrl}#menu?location=${specifiedLocationSlug}`;
      }

      // if active location with no specified location, default to active location
      if (activeCartLocationSlug) {
        return `${baseOrderUrl}#menu?location=${activeCartLocationSlug}`;
      }

      // if getting started disabled, and no specified or active location, default to favorite or first location
      if (!activeCartLocationSlug) {
        const favoriteLocationSlug = currentUser?.followers.find(follower => follower.restaurantId === restaurant.id).favoriteLocation?.slug || null;

        return `${baseOrderUrl}#menu?location=${favoriteLocationSlug || restaurantLocations[0]?.slug}`;
      }

      // if getting started enabled, and no specified or active location, visit getting started
      return baseOrderUrl;
    },
    [
      activeCartLocationSlug,
      currentUser?.followers,
      data?.restaurant?.onlineOrderCateringUrl,
      orderPageUrl,
      restaurant.id,
      restaurantLocations,
    ],
  );

  const provided = useMemo(
    () => ({
      activeProfilePage,
      activeUser: {
        avatarUrl: user?.avatarUrl,
        name: user?.name,
        profileCreationDate: formatDate(user?.createdAt),
        unreadOffers: user?.orderingOfferCodes?.length,
        unusedGiftcards: user?.userGiftCards?.length,
        userId: currentSession.user?.id,
      },
      changeActivePage: setActiveProfilePage,
      FETCH_LIMIT: 5,
      giftCards: user?.userGiftCards,
      loading,
      orderHistory: user?.orderHistory,
      orderingOfferCodes: user?.orderingOfferCodes,
      profileNavigationHeight: 70,
      profileNavigationWidth: 240,
      profilePages,
      renderOrderCartUrl,
      restaurant,
      restaurantLocations,
    }),
    [
      activeProfilePage,
      loading,
      profilePages,
      restaurantLocations,
      user,
      currentSession.user?.id,
      restaurant,
      renderOrderCartUrl,
    ],
  );

  let value;
  if (!loading && data && data.guestProfileUser) {
    value = provided;
  } else {
    value = INITIAL_PROFILE_STATE;
  }

  return (
    <ProfileContext.Provider value={value}>
      {children}
    </ProfileContext.Provider>
  );
};

ProfileContextProvider.defaultProps = {
  currentUser: null,
};

ProfileContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  currentSession: PropTypes.object.isRequired,
  currentUser: currentUserShape,
  restaurant: PropTypes.object.isRequired,
};

export default compose(
  withCurrentSession,
  withCurrentUser,
  withRestaurant,
)(withWidth()(ProfileContextProvider));
