import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { compose } from '@shakacode/recompose';
import { useMutation } from '~/lazy_apollo/client';
import { getApolloClient } from '~/lazy_apollo';

import { Box, Button } from '@popmenu/common-ui';

import { withPopmenuConfig } from '../../../utils/withPopmenuConfig';
import { popmenuConfigShape } from '../../../utils/popmenuConfigShape';
import { withSnackbar } from '../../../utils/withSnackbar';
import destroySessionMutation from '../../../libs/gql/mutations/users/destroySessionMutation.gql';
import currentSessionQuery from '../../../libs/gql/queries/users/currentSessionQuery.gql';

import { closeProfileV2Modal } from '../../../shared/ModalActions';

const ProfileSignOutButton = ({ popmenuConfig, showSnackbarError }) => {
  const [destroySession, { loading }] = useMutation(destroySessionMutation);
  const dispatch = useDispatch();

  const handleSignOut = () => {
    if (loading) {
      return;
    }
    destroySession().then(({ data }) => {
      getApolloClient().then(client => client.writeQuery({
        data: {
          currentSession: data.destroySession,
          popmenuConfig,
        },
        query: currentSessionQuery,
      }));

      dispatch(closeProfileV2Modal());
    }).catch((err) => {
      showSnackbarError(err);
    });
  };

  return (
    <Box my={1} mx={2}>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => handleSignOut()}
      >
        <FormattedMessage id="sessions.sign_out" defaultMessage="Sign Out" />
      </Button>
    </Box>
  );
};

ProfileSignOutButton.propTypes = {
  popmenuConfig: popmenuConfigShape.isRequired,
  showSnackbarError: PropTypes.func.isRequired,
};

export default compose(
  withPopmenuConfig,
  withSnackbar,
)(ProfileSignOutButton);
