import React, { useState, useContext } from 'react';
import { Box, Icon, Typography } from '@popmenu/common-ui';
import { Heart, ThumbsUp } from '@popmenu/web-icons';
import { compose } from '@shakacode/recompose';
import { useQuery } from '~/lazy_apollo/client';
import { FormattedMessage } from 'react-intl';
import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import { currentUserShape, withCurrentUser } from '../../../../shared/CurrentSessionProvider';
import followerPaginatedFavoritesQuery from '../../../../libs/gql/queries/followers/followerPaginatedFavoritesQuery.gql';
import { ProfileContext } from '../../../../shared/ProfileContextProvider';
import { formatDate, formatRelativeTime } from '../../../../utils/time';

import Loading from '../../../../shared/Loading';
import PoppedEmptyState from '../../../../assets/svg/popmenu_guest_profile_favorites_popped_empty.svg';
import DishCard from './DishCard';
import { profileFavoriteCardStyles } from '../../profile.styles';

const useStyles = makeStyles(profileFavoriteCardStyles);

const PoppedDishes = (props) => {
  const classes = useStyles();
  const {
    FETCH_LIMIT,
    profileNavigationHeight,
  } = useContext(ProfileContext);
  const [page, setPage] = useState(1);
  const { data, loading } = useQuery(
    followerPaginatedFavoritesQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        pagination: {
          limit: FETCH_LIMIT,
          offset: FETCH_LIMIT * (page - 1),
          sortBy: 'createdAt',
          sortDir: 'desc',
        },
        restaurantId: props.restaurant.id,
        userId: props.currentUser.id,
      },
    },
  );

  const isMobile = isWidthDown('sm', props.width);

  if (loading) {
    return <Loading size="lg" />;
  }

  const pageClickHandler = (event, value) => {
    setPage(value);
  };

  const pops = !loading && data.followerByRestaurant.paginatedPops;
  return (
    <React.Fragment>
      {pops.count > 0 ? (
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          height={`calc(${props.windowHeight}px - 130px - ${profileNavigationHeight}px - ${props.tabHeight}px)`}
        >
          <Box overflow="auto">
            {pops.records.map((pop) => {
              const time = formatRelativeTime(pop.createdAt);
              return (
                <DishCard
                  dishable={pop.dishable}
                  menuItemId={pop.item?.id}
                  dishClickHandler={props.dishClickHandler}
                  slug={pop.item?.slug}
                  tab="popped"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    flex={1}
                    marginLeft={2}
                    onClick={() => isMobile && props.dishClickHandler(pop.item?.id, 'popped')}
                  >
                    <Box
                      onClick={() => props.dishClickHandler(pop.item?.id, 'popped')}
                      className={pop.item && classes.pseudoButton}
                      data-cy={`dish_title_${pop.item?.slug}`}
                    >
                      <Typography variant="body1" color={pop.item ? 'textPrimary' : 'textSecondary'}>
                        <Icon icon={pop.popType === 'liked_it' ? ThumbsUp : Heart} />
                        <FormattedMessage
                          id="models.guest_profile.favorites.popped_dishes.dish_title"
                          defaultMessage=" {dishName}"
                          values={{ dishName: pop.dishable.name }}
                        />
                      </Typography>
                    </Box>
                    <Box color="gray.dark">
                      <Typography variant="caption">
                        {time.includes('ago') || time.includes('just') ? (
                          <FormattedMessage
                            id="models.guest_profile.favorites.popped_dishes.time_ago"
                            defaultMessage="{time}"
                            values={{ time: time.includes('ago') ? time : 'Just now' }}
                          />
                        ) : (
                          <React.Fragment>
                            <FormattedMessage
                              id="models.guest_profile.favorites.popped_dishes.date"
                              defaultMessage="{date} "
                              values={{ date: formatDate(pop.createdAt) }}
                            />
                          </React.Fragment>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </DishCard>
              );
            })}
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            paddingTop={1}
          >
            <Pagination
              count={Math.ceil(pops.count / FETCH_LIMIT)}
              page={page}
              onChange={pageClickHandler}
            />
          </Box>
        </Box>
      ) : (
        <Box
          width="100%"
          paddingTop={7}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <PoppedEmptyState height={200} />
          <Box paddingTop={2} color="gray.dark">
            <FormattedMessage id="models.guest_profile.favorites_popped.empty_state" defaultMessage="No popped dishes yet" />
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

PoppedDishes.propTypes = {
  currentUser: currentUserShape.isRequired,
};

export default compose(
  withCurrentUser,
)(withWidth()(PoppedDishes));
