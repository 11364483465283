import React, { useState, useContext } from 'react';
import { Box, Typography } from '@popmenu/common-ui';
import { compose } from '@shakacode/recompose';
import { useQuery } from '~/lazy_apollo/client';
import { FormattedMessage } from 'react-intl';
import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { currentUserShape, withCurrentUser } from '../../../../shared/CurrentSessionProvider';
import followerPaginatedFavoritesQuery from '../../../../libs/gql/queries/followers/followerPaginatedFavoritesQuery.gql';
import Loading from '../../../../shared/Loading';
import { formatDate, formatRelativeTime } from '../../../../utils/time';
import DishReview from './DishReview';
import ReviewedEmptyState from '../../../../assets/svg/popmenu_guest_profile_favorites_reviewed_empty.svg';
import { ProfileContext } from '../../../../shared/ProfileContextProvider';
import DishCard from './DishCard';
import { profileFavoriteCardStyles } from '../../profile.styles';

const useStyles = makeStyles(profileFavoriteCardStyles);

const ReviewedDishes = (props) => {
  const classes = useStyles();
  const {
    FETCH_LIMIT,
    profileNavigationHeight,
  } = useContext(ProfileContext);
  const [page, setPage] = useState(1);
  const { data, loading } = useQuery(
    followerPaginatedFavoritesQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        pagination: {
          limit: FETCH_LIMIT,
          offset: FETCH_LIMIT * (page - 1),
          sortBy: 'createdAt',
          sortDir: 'desc',
        },
        restaurantId: props.restaurant.id,
        userId: props.currentUser.id,
      },
    },
  );

  if (loading) {
    return <Loading size="lg" />;
  }

  const pageClickHandler = (event, value) => {
    setPage(value);
  };

  const reviews = !loading && data.followerByRestaurant.paginatedReviews;
  return (
    <React.Fragment>
      {reviews.count > 0 ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height={`calc(${props.windowHeight}px - 130px - ${profileNavigationHeight}px - ${props.tabHeight}px)`}
        >
          <Box overflow="auto">
            {reviews.records.map((review) => {
              const time = formatRelativeTime(review.createdAt);
              return (
                <DishCard
                  dishable={review.dishable}
                  menuItemId={review.item?.id}
                  dishClickHandler={props.dishClickHandler}
                  slug={review.item?.slug}
                  tab="reviewed"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    flex={1}
                    marginLeft={2}
                  >
                    <Box
                      onClick={() => props.dishClickHandler(review.item?.id, 'reviewed')}
                      className={review.item && classes.pseudoButton}
                      data-cy={`dish_title_${review.item?.slug}`}
                    >
                      <Typography variant="body1" color={review.item ? 'textPrimary' : 'textSecondary'}>
                        <FormattedMessage id="models.guest_profile.favorites.reviewed_dishes.dish_name" defaultMessage="{dishName}" values={{ dishName: review.dishable.name }} />
                      </Typography>
                    </Box>
                    <Box color="gray.dark">
                      <Typography variant="caption">
                        {time.includes('ago') || time.includes('just') ? (
                          <FormattedMessage
                            id="models.guest_profile.favorites.popped_dishes.time_ago"
                            defaultMessage="{time}"
                            values={{ time: time.includes('ago') ? time : 'Just now' }}
                          />
                        ) : (
                          <React.Fragment>
                            <FormattedMessage
                              id="models.guest_profile.favorites.popped_dishes.date"
                              defaultMessage="{date} "
                              values={{ date: formatDate(review.createdAt) }}
                            />
                          </React.Fragment>
                        )}
                      </Typography>
                    </Box>
                    {<DishReview review={review} />}
                  </Box>
                </DishCard>
              );
            })}
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            paddingTop={1}
          >
            <Pagination
              count={Math.ceil(reviews.count / FETCH_LIMIT)}
              page={page}
              onChange={pageClickHandler}
            />
          </Box>
        </Box>
      ) : (
        <Box
          width="100%"
          paddingTop={7}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <ReviewedEmptyState height={200} />
          <Box paddingTop={2} color="gray.dark">
            <FormattedMessage id="models.guest_profile.favorites_reviewed.empty_state" defaultMessage="No reviewed dishes yet" />
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

ReviewedDishes.propTypes = {
  currentUser: currentUserShape.isRequired,
};

export default compose(
  withCurrentUser,
)(ReviewedDishes);
