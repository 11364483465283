import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography, Icon } from '@popmenu/common-ui';
import { ShoppingBag } from '@popmenu/web-icons';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '~/lazy_apollo/client';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { createEvent } from '~/utils/eventable';
import { formatCurrency } from '../../../../utils/utils';
import { useSnackbar } from '../../../../utils/withSnackbar';
import { setMenuItemCartLocationId, setMenuItemCartNeedsUpdate } from '../../../../shared/MenuItemCartActions';
import { closeProfileV2Modal } from '../../../../shared/ModalActions';
import { openAddToCartModal, setDiscrepancyMenuItemIds } from '../../../../shared/DishActions';
import cloneMenuItemCartSelectedMenuItemMutation from '../../../../libs/gql/mutations/menu_item_carts/cloneMenuItemCartSelectedMenuItemMutation.gql';

import DishEmptyState from '../../../../assets/svg/popmenu_dish_empty.svg';
import OrderHistoryDishExtras from './OrderHistoryDishExtras';
import { pofileOrderHistoryDishStyles } from '../../profile.styles';

const useStyles = makeStyles(pofileOrderHistoryDishStyles);

const OrderHistoryDish = ({ cartOloUrl, location, orderDish, dishIndex }) => {
  const dispatch = useDispatch();
  const { spacing } = useTheme();
  const { showSnackbar, showSnackbarError } = useSnackbar();
  const dishIsAvailable = orderDish?.menuItem?.isOrderingEnabled && orderDish?.menuItem?.menu?.menuOrderingUrl && !orderDish?.menuItem?.outOfStock;
  const classes = useStyles(dishIsAvailable);
  const history = useHistory();
  const [cloneMenuItemCartSelectedMenuItem] = useMutation(cloneMenuItemCartSelectedMenuItemMutation);

  const showDishSnackbar = () => showSnackbar((
    <Box display="flex">
      <Box
        height={spacing(8)}
        width={spacing(8)}
        marginRight={1}
      >
        {orderDish?.menuItem?.featuredPhoto?.thumbnailUrl ? (
          <img
            alt={orderDish.menuItemName}
            src={orderDish?.menuItem?.featuredPhoto?.thumbnailUrl}
            style={{
              borderRadius: '4px',
              height: '100%',
              objectFit: 'cover',
              width: '100%',
            }}
          />
        ) : <DishEmptyState className={classes.dishImage} />}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Typography variant="body2">
          <FormattedMessage
            id="profile.order_dish_name"
            defaultMessage="{dishName}"
            values={{ dishName: orderDish.menuItemName }}
          />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage id="consumer.ordering.updated_order" defaultMessage="🎉 Updated Order!" />
        </Typography>
      </Box>
    </Box>
  ), {
    anchorOrigin: {
      horizontal: 'right',
      vertical: 'top',
    },
    autoHideDuration: 2000,
    variant: 'info',
  });

  const onReorder = () => {
    if (!dishIsAvailable) {
      return;
    }
    createEvent({
      eventableType: 'GuestProfile',
      eventLabel: 'profile | order_history | past_order | re_order_button | re_order',
      eventType: 'profile_order_history_re_order_click',
    });

    dispatch(setMenuItemCartLocationId(location.id));
    cloneMenuItemCartSelectedMenuItem({
      variables: {
        selectedMenuItemId: orderDish.id,
      },
    }).then(() => {
      showDishSnackbar();
      dispatch(setMenuItemCartNeedsUpdate(true));
      dispatch(closeProfileV2Modal());
      history.push(cartOloUrl);
    }).catch((error) => {
      if (error.message === 'Sorry, that item is currently not available') {
        history.push(cartOloUrl);
        dispatch(setDiscrepancyMenuItemIds([orderDish?.menuItem?.id]));
        dispatch(openAddToCartModal(orderDish?.menuItem?.id, orderDish?.menuItem?.menu?.id));
        dispatch(closeProfileV2Modal());
      } else {
        showSnackbarError(error);
      }
    });
  };

  return (
    <Box
      display="flex"
      marginTop={2}
      justifyContent="space-between"
    >
      <Box display="flex" width="100%">
        <Box
          borderRadius="borderRadius"
          overflow="hidden"
          height={64}
          width={64}
        >
          {orderDish?.menuItem?.featuredPhoto?.thumbnailUrl ? (
            <img
              alt={orderDish.menuItemName}
              src={orderDish?.menuItem?.featuredPhoto?.thumbnailUrl}
              className={classes.dishImage}
            />
          ) : <DishEmptyState className={classes.dishImage} />}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          flex={1}
        >
          <Box
            display="flex"
            flexDirection="column"
            marginLeft={1}
          >
            <Typography variant="body2" color={dishIsAvailable ? 'textPrimary' : 'textSecondary'}>
              <FormattedMessage
                id="profile.order_dish_name"
                defaultMessage="{dishName}"
                values={{ dishName: orderDish.menuItemName }}
              />
            </Typography>
            <Typography variant="caption" color="textSecondary">
              <FormattedMessage
                id="profile.order_dish_price"
                defaultMessage="{price}"
                values={{ price: `${formatCurrency(orderDish.price, location.currency, { showDecimals: true, showSymbol: true })}` }}
              />
            </Typography>
            {orderDish.selectedExtraGroups.length > 0 && orderDish.selectedExtraGroups.map(selectedGroup => (
              <OrderHistoryDishExtras selectedGroup={selectedGroup} />
            ))}
          </Box>
          <Box
            display="flex"
            alignItems="flex-end"
            justifyContent="flex-end"
            minWidth={100}
            color="info.dark"
          >
            <Button
              variant="text"
              textTransform="none"
              disabled={!dishIsAvailable}
              onClick={() => dishIsAvailable && onReorder()}
              data-cy={`re_order_dish_${dishIndex}`}
              color="inherit"
            >
              <Icon icon={ShoppingBag} color="inherit" />
              <Box marginLeft={1} color="inherit">
                {dishIsAvailable ? (
                  <FormattedMessage id="models.guest_profile.favorites.remind_try.out_of_stock" defaultMessage="Re-order" />
                ) : (
                  <FormattedMessage id="models.guest_profile.favorites.remind_try.add_to_order" defaultMessage="Unavailable" />
                )}
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

OrderHistoryDish.propTypes = {
  cartOloUrl: PropTypes.string.isRequired,
  dishIndex: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  orderDish: PropTypes.object.isRequired,
};

export default OrderHistoryDish;
