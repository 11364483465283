import React, { useContext } from 'react';
import { Box } from '@popmenu/common-ui';
import { FormattedMessage } from 'react-intl';

import { ProfileContext } from '../../../../shared/ProfileContextProvider';
import GiftcardsEmptyState from '../../../../assets/svg/no_gift_cards.svg';

import GiftCardsCard from './GiftCardsCard';

const GiftCardsPage = () => {
  const {
    activeUser,
    giftCards,
  } = useContext(ProfileContext);

  if (activeUser.unusedGiftcards < 1) {
    return (
      <Box
        width="100%"
        paddingTop={7}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <GiftcardsEmptyState height={200} />
        <Box paddingTop={2} color="gray.dark">
          <FormattedMessage id="profile.profile_no_gift_cards" defaultMessage="No Gift Card Yet" />
        </Box>
      </Box>
    );
  }

  return (
    <Box flexGrow={1} overflow="auto">
      {giftCards.map(giftCard => (
        <Box marginBottom={1}>
          <GiftCardsCard
            key={giftCard.id}
            giftCard={giftCard}
          />
        </Box>
      ))}
    </Box>
  );
};

export default GiftCardsPage;
