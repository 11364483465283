import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import { ListItem, ListItemText } from '@popmenu/common-ui';
import { makeStyles } from '@material-ui/core/styles';

import { propTypeDimension } from '~/utils/propTypes';
import { createEvent } from '~/utils/eventable';
import { ProfileContext } from '../../../shared/ProfileContextProvider';

import ProfileNavItemIcon from './ProfileNavItemIcon';
import ProfileNavItemCount from './ProfileNavItemCount';
import { profileNavItemStyles } from '../profile.styles';

const useStyles = makeStyles(profileNavItemStyles);

const ProfileNavItem = ({ profilePage, width }) => {
  const classes = useStyles();
  const {
    activeProfilePage,
    changeActivePage,
  } = useContext(ProfileContext);

  const handleChangeActivePage = (profilePageId) => {
    createEvent({
      eventableType: 'GuestProfile',
      eventLabel: `profile | sidebar | ${profilePageId}_button | ${profilePageId}`,
      eventType: `profile_sidebar_${profilePageId}_click`,
    });
    changeActivePage(profilePage.id);
  };

  const selected = activeProfilePage === profilePage.id;

  const isMobile = isWidthDown('sm', width);

  return (
    <ListItem
      button
      classes={classes}
      onClick={() => handleChangeActivePage(profilePage.id)}
      selected={selected}
      data-cy={`profile_nav_${profilePage.id}`}
    >
      <ProfileNavItemIcon icon={profilePage.icon} selected={selected} />
      <ListItemText>
        <FormattedMessage id={`profile.nav_item_${profilePage.id}`} defaultMessage={profilePage.title} />
      </ListItemText>
      {(profilePage.hasCount || isMobile) && (
        <ProfileNavItemCount
          profilePage={profilePage}
        />
      )}
    </ListItem>
  );
};

ProfileNavItem.propTypes = {
  profilePage: PropTypes.object.isRequired,
  width: propTypeDimension.isRequired,
};

export default withWidth()(ProfileNavItem);
