import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Card, CardContent, Typography, Icon } from '@popmenu/common-ui';
import { ChevronDown, ChevronUp } from '@popmenu/web-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse, Divider } from '@material-ui/core';
import { useMutation, useQuery } from '~/lazy_apollo/client';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { createEvent } from '~/utils/eventable';
import { formatDate } from '../../../../utils/time';

import pendingMenuItemCartQuery from '../../../../libs/gql/queries/menu_item_carts/pendingMenuItemCartQuery.gql';
import { ProfileContext } from '../../../../shared/ProfileContextProvider';
import { setMenuItemCartLocationId, setMenuItemCartNeedsUpdate } from '../../../../shared/MenuItemCartActions';
import { openAddToCartModal, setDiscrepancyMenuItemIds } from '../../../../shared/DishActions';
import cloneMenuItemCartMutation from '../../../../libs/gql/mutations/menu_item_carts/cloneMenuItemCartMutation.gql';
import { closeProfileV2Modal } from '../../../../shared/ModalActions';
import { useSnackbar } from '../../../../utils/withSnackbar';
import OrderHistoryDish from './OrderHistoryDish';
import { profileOrderHistoryCardStyles } from '../../profile.styles';

const useStyles = makeStyles(profileOrderHistoryCardStyles);

const fulfillmentTypes = {
  delivery_fulfillment_type: 'Delivery ',
  dine_in_fulfillment_type: 'DineIn ',
  pickup_fulfillment_type: 'Pickup ',
};

const OrderHistoryCard = ({ index, orderCart }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { renderOrderCartUrl } = useContext(ProfileContext);
  const { showSnackbar, showSnackbarError } = useSnackbar();
  const cartType = useSelector(state => state.menuItemCart.menuItemCartType);
  const [showCard, setShowCard] = useState(false);
  const receiptUrl = orderCart.messages.find(message => message.status === 'accepted_status')?.messageHtmlUrl;
  const history = useHistory();
  const [cloneMenuItemCart] = useMutation(cloneMenuItemCartMutation);

  const { data } = useQuery(
    pendingMenuItemCartQuery,
    {
      fetchPolicy: 'cache-and-network',
      skip: !showCard,
      variables: {
        cartType,
        locationId: orderCart.location.id,
      },
    },
  );

  const cartOloUrl = renderOrderCartUrl(orderCart.location.slug, orderCart.cartType === 'catering_cart_type');

  const showCartSnackbar = () => showSnackbar((
    <Box display="flex" justifyContent="space-around">
      <Typography variant="body2">
        <FormattedMessage id="consumer.ordering.updated_order" defaultMessage="🎉 Updated Order!" />
      </Typography>
    </Box>
  ), {
    anchorOrigin: {
      horizontal: 'right',
      vertical: 'top',
    },
    autoHideDuration: 2000,
    variant: 'info',
  });

  const onReorder = () => {
    dispatch(setMenuItemCartLocationId(orderCart.location.id));
    cloneMenuItemCart({
      variables: {
        menuItemCartId: orderCart.id,
        pendingMenuItemCartId: data?.currentSession?.pendingMenuItemCart?.id,
      },
    }).then((cartData) => {
      history.push(cartOloUrl);
      if (cartData) {
        dispatch(setMenuItemCartNeedsUpdate(true));
        dispatch(closeProfileV2Modal());
        showCartSnackbar();

        const rejectedMenuItemIds = cartData.data.cloneMenuItemCart.rejectedSelectedMenuItems;
        if (rejectedMenuItemIds.length > 0) {
          dispatch(openAddToCartModal(rejectedMenuItemIds[0]));
          dispatch(setDiscrepancyMenuItemIds(rejectedMenuItemIds));
        }
      }
    }).catch((error) => {
      showSnackbarError(error);
    });
  };

  return (
    <React.Fragment>
      <Card classes={classes}>
        <Box
          display="flex"
          justifyContent="space-between"
          onClick={() => {
            createEvent({
              eventableType: 'GuestProfile',
              eventLabel: 'profile | order_history | past_order_card',
              eventType: 'profile_order_history_drawer_click',
            });
            setShowCard(state => !state);
          }}
          data-cy={`cart_collapse_${index}`}
          className={classes.toggleCollapse}
        >
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="body1">
                  {fulfillmentTypes[orderCart.fulfillmentType]}
                  <FormattedMessage
                    id="profile.order_location"
                    defaultMessage="({locationName})"
                    values={{ locationName: orderCart.location.name }}
                  />
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box>
                <Typography variant="caption" color="textSecondary">
                  <FormattedMessage
                    id="profile.order_slug"
                    defaultMessage="Order #{orderSlug}"
                    values={{ orderSlug: orderCart.slug }}
                  />
                </Typography>
              </Box>
              <Box height="100%">
                <Typography variant="caption" color="textSecondary">
                  <FormattedMessage
                    id="profile.order_payment_capture"
                    defaultMessage="{date}"
                    values={{ date: formatDate(orderCart.submittedAt, 'MMM d, yyyy') || 'Time N/A' }}
                  />
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Icon icon={showCard ? ChevronUp : ChevronDown} size="extra-large" />
          </Box>
        </Box>
        <Collapse in={showCard} timeout="auto" unmountOnExit>
          <CardContent className={classes.cardContent}>
            {orderCart.selectedMenuItems.map((dish, dishIndex) => (
              <Box marginY={2}>
                <Divider />
                <OrderHistoryDish
                  dishIndex={dishIndex}
                  cartOloUrl={cartOloUrl}
                  location={orderCart.location}
                  orderDish={dish}
                />
              </Box>
            ))}
            <Box marginY={2}>
              <Divider />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box flexGrow="1" marginRight={1}>
                {receiptUrl ? (
                  <a
                    href={receiptUrl}
                    rel="noreferrer"
                    target="_blank"
                    data-cy={receiptUrl}
                  >
                    <Button
                      fullWidth
                      variant="outlined"
                    >
                      <FormattedMessage
                        id="profile.order_receipt"
                        defaultMessage="View Receipt"
                      />
                    </Button>
                  </a>
                ) : (
                  <Button
                    variant="outlined"
                    disabled
                  >
                    <FormattedMessage
                      id="profile.order_receipt_unavailable"
                      defaultMessage="Receipt Unavailable"
                    />
                  </Button>
                )}
              </Box>
              <Box flexGrow="4">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    createEvent({
                      eventableType: 'GuestProfile',
                      eventLabel: 'profile | order_history | past_order | re_order_all_button | re_order_all',
                      eventType: 'profile_order_history_re_order_all_click',
                    });
                    onReorder();
                  }}
                >
                  <FormattedMessage
                    id="profile.reorder"
                    defaultMessage="Re-order All"
                  />
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Collapse>
      </Card>
    </React.Fragment>
  );
};

OrderHistoryCard.propTypes = {
  index: PropTypes.number.isRequired,
  orderCart: PropTypes.object.isRequired,
};

export default OrderHistoryCard;
