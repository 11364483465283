import React from 'react';
import { Box } from '@popmenu/common-ui';
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DishEmptyState from '../../../../assets/svg/popmenu_dish_empty.svg';
import { profileFavoriteCardStyles } from '../../profile.styles';

const useStyles = makeStyles(profileFavoriteCardStyles);

const DishCard = (props) => {
  const classes = useStyles(!!props.menuItemId);
  return (
    <Box
      display="flex"
      flexDirection="column"
      className={props.menuItemId && classes.cardContainer}
    >
      <Box display="flex">
        <Box
          borderRadius="borderRadius"
          overflow="hidden"
          height={100}
          width={100}
          onClick={() => props.dishClickHandler(props.menuItemId, props.tab)}
          className={props.menuItemId && classes.pseudoButton}
          data-cy={`dish_photo_${props.slug}`}
        >
          {props.dishable.featuredPhotoUrl ? (
            <img
              alt={props.dishable.name}
              src={props.dishable.featuredPhotoUrl}
              className={classes.cardImg}
            />
          ) : <DishEmptyState className={classes.cardImg} />}
        </Box>
        {props.children}
      </Box>
      <Box marginY={2}>
        <Divider />
      </Box>
    </Box>
  );
};

export default DishCard;
