import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@popmenu/common-ui';
import { Alert, AlertTitle } from '@popmenu/admin-ui';
import { Snackbar } from '@material-ui/core';

type closeFunctionType = () => void;
interface WrongLocationAlertProps {
  open: boolean
  closeFunction: closeFunctionType
}

const WrongLocationAlert = ({ open, closeFunction }: WrongLocationAlertProps) => (
  <Snackbar
    onClose={() => closeFunction()}
    open={open}
    autoHideDuration={4000}
    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
  >
    <Alert
      style={{ marginTop: '8px' }}
      fullWidth
      severity={'error'}
    >
      <AlertTitle>
        <FormattedMessage
          id="wrong_location.title.invalid_location"
          defaultMessage="Offer location not valid"
        />
      </AlertTitle>
      <Typography>
        <FormattedMessage
          id="wrong_location.description.invalid_location"
          defaultMessage="This offer is not applicable to the location you are currently ordering from"
        />
      </Typography>
    </Alert>
  </Snackbar>
);

export default WrongLocationAlert;
