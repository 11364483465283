import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Chip, ListItemSecondaryAction, Icon } from '@popmenu/common-ui';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { ChevronRight } from '@popmenu/web-icons';

import { propTypeDimension } from '~/utils/propTypes';
import { profileNavItemCountStyles } from '../profile.styles';

import { ProfileContext } from '../../../shared/ProfileContextProvider';

const useStyles = makeStyles(profileNavItemCountStyles);

const ProfileNavItemCount = ({ profilePage, width }) => {
  const classes = useStyles();
  const {
    activeUser,
    profilePages,
  } = useContext(ProfileContext);

  const isMobile = isWidthDown('sm', width);

  return (
    <ListItemSecondaryAction className={classes.secondaryAction}>
      {profilePages[profilePage.id].hasCount && activeUser[profilePage.profileCount] > 0 && (
        <Chip classes={classes} label={activeUser[profilePage.profileCount]} />
      )}
      {isMobile && (
        <Icon icon={ChevronRight} style={{ fontSize: '36px' }} />
      )}
    </ListItemSecondaryAction>
  );
};

ProfileNavItemCount.propTypes = {
  profilePage: PropTypes.object.isRequired,
  width: propTypeDimension.isRequired,
};

export default withWidth()(ProfileNavItemCount);
