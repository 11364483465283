export const profileAvatarBadgeStyles = ({ palette, typography }) => ({
  icon: {
    fontSize: typography.body2.fontSize,
  },
  pseudoButton: {
    cursor: 'pointer',
  },
  root: {
    backgroundColor: palette.grey.A400,
    color: palette.white.main,
    cursor: 'pointer',
  },
});

export const profilePageContentRegionStyles = ({ typography }) => ({
  title: {
    fontWeight: typography.fontWeightBold,
  },
});

export const profileDrawerStyles = ({ breakpoints }) => ({
  paper: {
    [breakpoints.down('md')]: {
      width: '100%',
    },
    width: '60%',
  },
});

export const profileHeaderStyles = ({ palette }) => ({
  container: {
    alignItems: 'center',
    backgroundColor: palette.white.main,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    zIndex: 100,
  },
});

export const profileNavItemStyles = ({ palette }) => ({
  root: {
    '&$selected': {
      '&:hover': {
        backgroundColor: palette.primary.light,
      },
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
    },
  },
  selected: {},
});

export const profileNavItemCountStyles = ({ palette, spacing }) => ({
  root: {
    backgroundColor: palette.red.main,
    borderRadius: '100px',
    color: palette.white.main,
    height: spacing(3),
  },
  secondaryAction: {
    alignItems: 'center',
    display: 'flex',
  },
});

export const profileNavItemIconStyles = ({ palette, spacing, typography }) => ({
  fontSize: typography.pxToRem(24),
  listItemIcon: props => ({
    color: palette[props.color][props.variant],
    minWidth: spacing(3.75),
  }),
  root: props => ({
    color: palette[props.color][props.variant],
  }),
});

export const profileOrderHistoryContainer = () => ({
  container: {
    overflowX: 'hidden',
    overflowY: 'auto',
  },
});

export const profileOrderHistoryCardStyles = ({ spacing }) => ({
  cardContent: {
    '&:last-child': {
      paddingBottom: spacing(1),
    },
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
  root: {
    border: 'solid',
    borderColor: '#e4e4e4',
    borderWidth: spacing(0.2),
    marginBottom: spacing(2),
    minHeight: spacing(10),
    padding: spacing(1),
  },
  toggleCollapse: {
    cursor: 'pointer',
  },
});

export const pofileOrderHistoryDishStyles = ({ palette, spacing }) => ({
  dishImage: {
    height: '100%',
    objectFit: 'cover',
    opacity: available => (available ? 1.0 : 0.5),
    width: '100%',
  },
  reorderOption: {
    color: palette.blue.light,
    cursor: 'pointer',
    minWidth: spacing(18),
  },
});
export const profileCardsStyles = ({ spacing }) => ({
  cardImg: {
    maxHeight: spacing(18.75),
    minWidth: 'fit-content',
    objectFit: 'cover',
    width: '100%',
  },
});

export const profileFavoriteCardStyles = () => ({
  cardImg: {
    height: '100%',
    objectFit: 'cover',
    opacity: available => (available ? 1.0 : 0.5),
    width: '100%',
  },
  pseudoButton: {
    cursor: 'pointer',
  },
});

export const profileGiftCardsDetailsStyles = () => ({
  primary: {
    fontWeight: 'bold',
  },
});

export const profileNavigationStyles = ({ spacing }) => ({
  popLogo: {
    height: spacing(2.25),
    marginLeft: spacing(0.5),
  },
});

export const profileOffersTagStyles = ({ palette, spacing, typography }) => ({
  button: {
    textTransform: 'none !important',
  },
  container: {
    borderColor: '#f4f4f4',
  },
  fontBold: {
    fontWeight: typography.fontWeightBold,
  },
  fontFamily: {
    color: '#000000',
    fontFamily: "'Nunito', 'Arial', 'sans-serif'",
  },
  offerCount: {
    display: 'flex',
    fontFamily: "'Proxima-Nova', 'Arial', 'sans-serif'",
    fontSize: `${spacing(1.5)}px !important`,
  },
  pseudoButton: {
    cursor: 'pointer',
  },
  root: {
    backgroundColor: palette.red.main,
    borderRadius: '100px',
    color: palette.white.main,
    cursor: 'pointer',
    height: spacing(2),
    padding: spacing(0, 0.75),
    width: '100%',
  },
  viewProfileText: {
    fontFamily: "'Proxima-Nova', 'Arial', 'sans-serif'",
    fontSize: `${spacing(2)}px !important`,
  },
});

export const userEmailCheckStyles = ({ mixins, palette, spacing, typography }) => ({
  badge: {
    position: 'absolute',
  },
  checkButton: {
    '& .MuiGrid-root': {
      height: '100%',
      padding: 0,
    },
    borderRadius: '0 4px 4px 0',
    height: '100%',
    margin: 0,
  },
  checkOffersFloatingButton: {
    background: palette.primary.main,
    borderRadius: spacing(4),
    color: palette.primary.contrastText,
    marginLeft: 'auto',
    padding: spacing(3, 0.5, 3, 0),
  },
  checkOffersFloatingButtonContainer: {
    [mixins.highZoomMediaQuery]: {
      bottom: spacing(13),
      visibility: 'visible',
    },
    bottom: spacing(11),
    marginBottom: spacing(3),
    marginLeft: 'auto', // Ensures it sticks to the right side
    overflow: 'hidden',
    position: 'sticky',
    visibility: 'hidden',
    width: 'max-content',
    zIndex: 11,
  },
  checkOffersFloatingButtonContainerBottom: {
    bottom: spacing(3),
  },
  container: {
    borderColor: '#f4f4f4',
  },
  emailField: {
    '& div': {
      borderRadius: '4px 0 0 4px',
    },
  },
  fontBold: {
    fontWeight: typography.fontWeightBold,
  },
  fontColor: {
    color: '#000000',
  },
  fontFamily: {
    fontFamily: "'Nunito', 'Arial', 'sans-serif'",
    fontSize: `${spacing(1.75)}px !important`,
  },
  newBadge: {
    display: 'flex',
    fontFamily: "'Proxima-Nova', 'Arial', 'sans-serif'",
    fontSize: `${spacing(1.5)}px !important`,
  },
  newContainer: {
    backgroundColor: palette.red.main,
    borderRadius: '100px',
    color: palette.white.main,
    cursor: 'pointer',
    display: 'flex',
    height: spacing(2),
    justifyContent: 'center',
    padding: spacing(0, 0.75),
    width: spacing(5),
  },
  root: {
    backgroundColor: palette.red.main,
    borderRadius: 100,
    color: palette.white.main,
    fontFamily: "'Proxima-Nova', 'Arial', 'sans-serif'",
    fontSize: `${spacing(1.5)}px !important`,
    height: '100%',
    width: '100%',
  },
});
