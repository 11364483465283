import React, { useContext } from 'react';
import { Box, Button } from '@popmenu/common-ui';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { ProfileContext } from '../../../../shared/ProfileContextProvider';
import { closeProfileV2Modal } from '../../../../shared/ModalActions';

import OrdersEmptyState from '../../../../assets/svg/popmenu_guest_profile_orders_empty.svg';
import OrderHistoryCard from './OrderHistoryCard';
import { profileOrderHistoryContainer } from '../../profile.styles';

const useStyles = makeStyles(profileOrderHistoryContainer);

const OrderHistoryPage = () => {
  const classes = useStyles();
  const {
    orderHistory,
    renderOrderCartUrl,
  } = useContext(ProfileContext);
  const history = useHistory();
  const dispatch = useDispatch();

  const onOrder = () => {
    history.push(renderOrderCartUrl());
    dispatch(closeProfileV2Modal());
  };

  if (orderHistory.length === 0) {
    return (
      <Box
        width="100%"
        paddingTop={7}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box paddingLeft={3}>
          <OrdersEmptyState height={200} />
        </Box>
        <Box paddingY={4} color="gray.dark">
          <FormattedMessage id="profile.order_history_empty" defaultMessage="No Orders Yet" />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onOrder()}
        >
          <FormattedMessage id="profile.order_history_order_now" defaultMessage="Order Now" />
        </Button>
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      {orderHistory.map((orderCart, index) => <OrderHistoryCard orderCart={orderCart} index={index} />)}
    </Box>
  );
};

export default OrderHistoryPage;
