import React, { useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Box, Typography } from '@popmenu/common-ui';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQuery } from '~/lazy_apollo/client';

import { createEvent } from '~/utils/eventable';
import { ProfileContext } from '../../../shared/ProfileContextProvider';
import { setOrderingOfferCode, setProfileEventTracking } from '../../../shared/ConsumerActions';
import { closeProfileV2Modal } from '../../../shared/ModalActions';
import { formatDate } from '../../../utils/time';
import locationCurrencySettingsQuery from '../../../libs/gql/queries/locations/locationCurrencySettingsQuery.gql';

import OfferDetailsModal from '../../online_ordering/OfferDetailsModal';
import { orderingOfferType } from '../../online_ordering/utils';
import BasicModal from '../../../admin/shared/BasicModal';

import { profileCardsStyles } from '../profile.styles';
import { AH } from '../../shared/AccessibleHeading';
import WrongLocationAlert from './WrongLocationAlert';

const useStyles = makeStyles(profileCardsStyles);

const OfferCard = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const isLocationOffer = props.locationSlug && props.locationName && props.locationId;

  const {
    renderOrderCartUrl,
  } = useContext(ProfileContext);

  const profileEventTracking = useSelector(state => state.consumer.profileEventTracking);
  const cartLocationId = useSelector(state => state.menuItemCart.menuItemCartLocationId);

  const [showOfferModal, setShowOfferModal] = useState(false);
  const [showWrongLocationAlert, setShowWrongLocationAlert] = useState(false);

  const getQualifyingDishes = (type) => {
    let dishes = [];
    const dishType = props.orderingOfferCode?.orderingOffer?.orderingOfferBxgyDetail[type];
    dishType.forEach((dish) => {
      dishes.push(dish.dish.name);
    });
    dishes = dishes.join(', ');
    return dishes;
  };
  const expirationDisplay = props.expirationDate ? formatDate(props.expirationDate, 'MM/dd/yyyy') : 'N/A';

  const { data } = useQuery(
    locationCurrencySettingsQuery,
    {
      variables: {
        locationId: cartLocationId,
      },
    },
  );

  const currency = data?.location?.currency;
  const getOfferValidLocationUrl = (sessionOrderingUrl) => {
    if (!cartLocationId) {
      let rootOrderingUrl = sessionOrderingUrl;
      if (rootOrderingUrl.includes('#menu?location=')) {
        rootOrderingUrl = sessionOrderingUrl.substring(0, sessionOrderingUrl.indexOf('#'));
      }
      return `${rootOrderingUrl}#menu?location=${props.locationSlug}`;
    } else if (cartLocationId === props.locationId) {
      return sessionOrderingUrl;
    }
    return undefined;
  };

  const dispatchOffer = (orderCartUrl) => {
    dispatch(setOrderingOfferCode(props.offerCode));
    if (!profileEventTracking.some(event => event === 'offer')) {
      dispatch(setProfileEventTracking([...profileEventTracking, 'offer']));
    }
    dispatch(closeProfileV2Modal());
    history.push(orderCartUrl);
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="column"
        border={1}
        borderRadius="borderRadius"
        borderColor="gray.dark"
        justifyContent="center"
        alignItems="center"
        padding={1.5}
        data-cy={'offer_card'}
      >
        {props.photo && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingBottom={1.5}
            overflow="hidden"
          >
            <img
              src={props.photo.thumbnailUrl}
              alt={props.photo.originalFilename}
              className={classes.cardImg}
            />
          </Box>
        )}
        <Box marginBottom={0.5}>
          <AH typography variant="h5" align="center">
            <FormattedMessage
              id="models.guest_profile.offer.offer_name"
              defaultMessage="{offer_name}"
              values={{ offer_name: props.offerName }}
            />
          </AH>
        </Box>
        { isLocationOffer && (
          <Box>
            <Typography>
              <FormattedMessage
                id="models.guest_profile.offer.specific_location"
                defaultMessage="Offer valid at {location} location"
                values={{ location: props.locationName }}
              />
            </Typography>
          </Box>
        )}
        { !isLocationOffer && (
          <Box>
            <Typography>
              <FormattedMessage
                id="models.guest_profile.offer.all_locations"
                defaultMessage="Offer valid at all locations"
              />
            </Typography>
          </Box>
        )}
        <Box>
          <Typography>
            <FormattedMessage
              id="models.guest_profile.offer.offer_code"
              defaultMessage="Offer Code: {code}"
              values={{ code: props.offerCode }}
            />
          </Typography>
        </Box>
        <Box>
          <FormattedMessage
            id="models.guest_profile.offer.offer_expiration"
            defaultMessage="Expire Date: {date}"
            values={{ date: expirationDisplay }}
          />
        </Box>
        <Box textAlign="center">
          <FormattedMessage
            id="models.guest_profile.offer.offer_location"
            defaultMessage="This offer applies to {offerType}"
            values={{ offerType: props.orderingOfferCode.orderingOffer.orderingOfferBxgyDetail ? 'select items' : orderingOfferType(props.orderingOfferCode) }}
          />
        </Box>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          marginTop={2}
        >
          <Box width="50%" paddingRight={1}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => setShowOfferModal(true)}
              data-cy={'see_details'}
            >
              <FormattedMessage
                id="models.guest_profile.offer.see_details"
                defaultMessage="See Details"
              />
            </Button>
          </Box>
          <Box width="50%" paddingLeft={1}>
            <Button
              data-cy={'redeem_now'}
              fullWidth
              variant="contained"
              disabled={!props.orderingOfferCode.orderingOffer.canBeUsedToday}
              color="primary"
              onClick={() => {
                createEvent({
                  eventableType: 'GuestProfile',
                  eventLabel: 'profile | offers | redeem_now_button | redeem_now',
                  eventType: 'profile_offers_redeem_now_click',
                });
                const orderingOnlineUrl = renderOrderCartUrl();
                if (isLocationOffer) {
                  const validOfferUrl = getOfferValidLocationUrl(orderingOnlineUrl);
                  if (!validOfferUrl) {
                    setShowWrongLocationAlert(true);
                  } else {
                    dispatchOffer(validOfferUrl);
                  }
                } else {
                  dispatchOffer(orderingOnlineUrl);
                }
              }}
            >
              <FormattedMessage
                id="models.guest_profile.offer.redeem_now"
                defaultMessage="Redeem Now"
              />
            </Button>
          </Box>
        </Box>
      </Box>
      <BasicModal
        closeModal={() => setShowOfferModal(false)}
        show={showOfferModal}
        size="sm"
        title="Offer Details"
      >
        <OfferDetailsModal
          locationName={props.locationName}
          orderingOfferCode={props.orderingOfferCode}
          getQualifyingDishes={getQualifyingDishes}
          amount={props.orderingOfferCode.orderingOffer.amount}
          currency={currency}
        />
      </BasicModal>
      <WrongLocationAlert open={showWrongLocationAlert} closeFunction={() => setShowWrongLocationAlert(false)} />
    </React.Fragment>
  );
};

export default OfferCard;
