import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, List, Typography, Icon } from '@popmenu/common-ui';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';

import { propTypeDimension } from '~/utils/propTypes';
import { profileNavigationStyles } from '../profile.styles';

import { ProfileContext } from '../../../shared/ProfileContextProvider';

import ProfileNavItems from './ProfileNavItem';
import ProfileAvatar from './ProfileAvatar';
import ProfileSignOutButton from './ProfileSignOutButton';
import PopmenuRed from '../../../assets/svg/popmenu_icon_red.svg';

const useStyles = makeStyles(profileNavigationStyles);

const ProfileNavigation = ({ width }) => {
  const {
    activeProfilePage,
    profilePages,
    profileNavigationWidth,
  } = useContext(ProfileContext);

  const classes = useStyles();

  const isMobile = isWidthDown('sm', width);

  const displayedPages = Object.values(profilePages).filter(
    page => page.display,
  ).sort((a, b) => a.order - b.order);

  if (!isMobile || (isMobile && activeProfilePage === 'my_profile')) {
    return (
      <Box
        width={
          /* eslint-disable sort-keys */
          { xs: '100%', md: profileNavigationWidth }
          /* eslint-enable sort-keys */
        }
        boxShadow={2}
        display="flex"
        flexDirection="column"
      >
        <ProfileAvatar />
        <List component="nav" aria-label="guest profile navigation">
          {displayedPages.map(profilePage => (
            <ProfileNavItems profilePage={profilePage} />
          ))}
        </List>
        <ProfileSignOutButton />
        <Box display="flex" justifyContent="center" alignItems="flex-end" flexGrow={1} pb={1}>
          <Typography variant="subtitle2" color="textSecondary">
            <FormattedMessage id="sessions.footer_text" defaultMessage="Powered By" />
          </Typography>
          <Icon icon={PopmenuRed} alt="popmenu" className={classes.popLogo} />
        </Box>
      </Box>
    );
  }

  return null;
};

ProfileNavigation.propTypes = {
  width: propTypeDimension.isRequired,
};

export default withWidth()(ProfileNavigation);
