import React, { useState, useContext } from 'react';
import { Box, Button, Icon, Typography } from '@popmenu/common-ui';
import { ShoppingBag } from '@popmenu/web-icons';
import { compose } from '@shakacode/recompose';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import { useQuery } from '~/lazy_apollo/client';
import { createEvent } from '~/utils/eventable';
import { formatCurrency } from '../../../../utils/currency';
import { currentUserShape, withCurrentUser } from '../../../../shared/CurrentSessionProvider';
import { withRestaurant } from '../../../../utils/withRestaurant';
import followerPaginatedFavoritesQuery from '../../../../libs/gql/queries/followers/followerPaginatedFavoritesQuery.gql';
import { ProfileContext } from '../../../../shared/ProfileContextProvider';
import { openEditMenuItemCartModalFromCustomPage } from '../../../../shared/DishActions';
import { setMenuItemCartLocationId, setMenuItemCartIsScheduled } from '../../../../shared/MenuItemCartActions';
import { closeProfileV2Modal } from '../../../../shared/ModalActions';

import Loading from '../../../../shared/Loading';
import DishCard from './DishCard';
import TryEmptyState from '../../../../assets/svg/popmenu_guest_profile_favorites_try_empty.svg';
import { profileFavoriteCardStyles } from '../../profile.styles';

const useStyles = makeStyles(profileFavoriteCardStyles);

const RemindToTry = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    FETCH_LIMIT,
    renderOrderCartUrl,
    profileNavigationHeight,
  } = useContext(ProfileContext);
  const [page, setPage] = useState(1);
  const { data, loading } = useQuery(
    followerPaginatedFavoritesQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        pagination: {
          limit: FETCH_LIMIT,
          offset: FETCH_LIMIT * (page - 1),
          sortBy: 'createdAt',
          sortDir: 'desc',
        },
        restaurantId: props.restaurant.id,
        userId: props.currentUser.id,
      },
    },
  );

  const isMobile = isWidthDown('sm', props.width);

  if (loading) {
    return <Loading size="lg" />;
  }

  const pageClickHandler = (event, value) => {
    setPage(value);
  };

  const openAddToCartModal = (locationId, menuItem) => {
    if (locationId && menuItem) {
      dispatch(setMenuItemCartIsScheduled(null));
      dispatch(setMenuItemCartLocationId(locationId));
      dispatch(openEditMenuItemCartModalFromCustomPage(menuItem.id, menuItem.menu?.id, 'remind_to_try'));
      dispatch(closeProfileV2Modal());
    }
  };

  const getSizePrices = (sizes, currency) => {
    if (!sizes) {
      return null;
    }
    const extras = [];

    // Sizes
    sizes.filter(size => size.name).forEach((size) => {
      extras.push(`${size.name} ${formatCurrency(size.price, currency, { showSymbol: true })}`);
    });

    if (isMobile) {
      return extras;
    }
    return extras.join(' · ');
  };

  const pops = !loading && data.followerByRestaurant.paginatedTrys;
  return (
    <React.Fragment>
      {pops.count > 0 ? (
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          height={`calc(${props.windowHeight}px - 130px - ${profileNavigationHeight}px - ${props.tabHeight}px)`}
        >
          <Box overflow="auto">
            {pops.records.map(pop => (
              <DishCard
                dishable={pop.dishable}
                menuItemId={pop.item?.id}
                dishClickHandler={props.dishClickHandler}
                slug={pop.item?.slug}
                tab="remind"
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  flex={1}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    marginLeft={2}
                    onClick={() => isMobile && props.dishClickHandler(pop.item?.id, 'remind_me')}
                  >
                    <Box
                      onClick={() => props.dishClickHandler(pop.item?.id, 'remind_me')}
                      className={pop.item && classes.pseudoButton}
                      data-cy={`dish_title_${pop.item?.slug}`}
                    >
                      <Typography variant="body1" color={pop.item ? 'textPrimary' : 'textSecondary'}>
                        <FormattedMessage id="models.guest_profile.favorites.remind_try.dish_name" defaultMessage="{dishName}" values={{ dishName: pop.dishable.name }} />
                      </Typography>
                    </Box>
                    {/* The price listing style will differ from web and mobile. In web, prices will be separated by middle dots. In mobile, they will be listed in a column. */}
                    <Box
                      color="gray.dark"
                      display={isMobile && 'flex'}
                      flexDirection={isMobile && 'column'}
                    >
                      {pop.item?.sizes && isMobile ? (
                        getSizePrices(pop.item?.sizes, pop.item?.currency).map((size, idx) => (
                          <Box>
                            <Typography variant="caption">
                              <FormattedMessage
                                id={`models.guest_profile.favorites.remind_try.dish_price_${idx}`}
                                defaultMessage={size}
                              />
                            </Typography>
                          </Box>
                        ))
                      ) : (
                        <Typography variant="caption">
                          <FormattedMessage
                            id="models.guest_profile.favorites.remind_try.dish_price"
                            defaultMessage="{price}"
                            values={{ price: formatCurrency(pop.item?.price, pop.item?.currency, { showSymbol: true }) }}
                          />
                        </Typography>
                      )}
                    </Box>
                    {pop.dishable.description && !isMobile && (
                      <Box>
                        <Typography variant="caption">
                          <FormattedMessage id="models.guest_profile.favorites.remind_try.dish_description" defaultMessage="{dishDescription}" values={{ dishDescription: pop.dishable.description }} />
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box
                    display="flex"
                    alignItems="flex-end"
                    justifyContent="center"
                    color="info.dark"
                    minWidth={isMobile ? 100 : 120}
                  >
                    <Button
                      color="inherit"
                      variant="text"
                      textTransform="none"
                      disabled={!pop.item?.id || pop.item?.outOfStock || !pop.item?.isOrderingEnabled}
                      component={pop.item?.outOfStock || !pop.item?.isOrderingEnabled ? undefined : Link}
                      onClick={() => {
                        if (pop.item?.locationId && pop.item?.id) {
                          createEvent({
                            eventableType: 'GuestProfile',
                            eventLabel: 'profile | favorites | remind_me | add_to_order | add_to_order',
                            eventType: 'profile_favorites_remind_me_add_to_order_click',
                          });
                          openAddToCartModal(pop.item?.locationId, pop.item);
                        }
                      }}
                      to={renderOrderCartUrl(pop.item?.menu.location.slug)}
                    >
                      {isMobile ? (
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                          color="inherit"
                        >
                          <Icon icon={ShoppingBag} color="inherit" />
                          <Box color="inherit">
                            {/* If the item doesn't have ordering enabled, display Unavailable */}
                            {pop.item?.isOrderingEnabled ? (
                              // If the item does have ordering enabled, check for stock. Allow Add to Order only if ordering is enabled and item is in stock.
                              pop.item?.outOfStock ? (
                                <FormattedMessage id="models.guest_profile.favorites.remind_try.out_of_stock" defaultMessage="Out Of Stock" />
                              ) : (
                                <FormattedMessage id="models.guest_profile.favorites.remind_try.add_to_order" defaultMessage="Add to Order" />
                              )
                            ) : (
                              <FormattedMessage id="models.guest_profile.favorites.remind_try.unavailable" defaultMessage="Unavailable" />
                            )}
                          </Box>
                        </Box>
                      ) : (
                        <React.Fragment>
                          <Icon icon={ShoppingBag} color="inherit" />
                          <Box marginLeft={1} color="inherit">
                            {/* If the item doesn't have ordering enabled, display Unavailable */}
                            {pop.item?.isOrderingEnabled ? (
                              // If the item does have ordering enabled, check for stock. Allow Add to Order only if ordering is enabled and item is in stock.
                              pop.item?.outOfStock ? (
                                <FormattedMessage id="models.guest_profile.favorites.remind_try.out_of_stock" defaultMessage="Out Of Stock" />
                              ) : (
                                <FormattedMessage id="models.guest_profile.favorites.remind_try.add_to_order" defaultMessage="Add to Order" />
                              )
                            ) : (
                              <FormattedMessage id="models.guest_profile.favorites.remind_try.unavailable" defaultMessage="Unavailable" />
                            )}
                          </Box>
                        </React.Fragment>
                      )}
                    </Button>
                  </Box>
                </Box>
              </DishCard>
            ))}
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            paddingTop={1}
          >
            <Pagination
              count={Math.ceil(pops.count / FETCH_LIMIT)}
              page={page}
              onChange={pageClickHandler}
            />
          </Box>
        </Box>
      ) : (
        <Box
          width="100%"
          paddingTop={7}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <TryEmptyState height={200} />
          <Box paddingTop={2} color="gray.dark">
            <FormattedMessage id="models.guest_profile.favorites_try.empty_state" defaultMessage="No dishes saved yet" />
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

RemindToTry.propTypes = {
  currentUser: currentUserShape.isRequired,
};

export default compose(
  withCurrentUser,
  withRestaurant,
)(withWidth()(RemindToTry));
