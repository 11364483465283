import React from 'react';
import { Box, Typography, CollapsibleText, useCollapsibleText } from '@popmenu/common-ui';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { profileFavoriteCardStyles } from '../../profile.styles';

const useStyles = makeStyles(profileFavoriteCardStyles);

const DishReview = ({ review }) => {
  const { collapsibleText, toggle } = useCollapsibleText();
  const classes = useStyles();
  return (
    <Box onClick={toggle} className={collapsibleText.collapsible && classes.pseudoButton}>
      <CollapsibleText
        {...collapsibleText}
        previewLines={2}
        text={review.content}
        typographyVariant="body2"
      />
      {collapsibleText.collapsible && (
        <Box color="blue.main">
          <Typography variant="caption">
            <FormattedMessage id="models.guest_profile.favorites.dish_review.see_more_less" defaultMessage="see {text}" values={{ text: collapsibleText.open ? 'less' : 'more' }} />
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default DishReview;
