import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatCurrency } from '../../utils/utils';
import { formatDate } from '../../utils/time';
import { orderingOfferType } from './utils';

const OfferDetailsModal = ({ currency, orderingOfferCode, getQualifyingDishes, amount, locationName }) => {
  const getFormattedSchedule = () => {
    const friendlySchedule = [];
    if (orderingOfferCode.orderingOffer.orderingOfferSchedule.friendlySchedule) {
      orderingOfferCode.orderingOffer.orderingOfferSchedule.friendlySchedule.split('|').forEach((v) => {
        friendlySchedule.push(v);
      });
    }
    return friendlySchedule;
  };

  const freeOfferType = (() => {
    const bxgyType = orderingOfferCode.orderingOffer?.orderingOfferBxgyDetail?.bxgyType;
    return (bxgyType && bxgyType !== 'default') ? bxgyType : null;
  })();

  const getLocationDescription = locationName ? `This offer applies to the ${locationName} location. The offer is excluded from other locations` : 'This offer is for all locations';

  return (
    <ul>

      <li>
        <FormattedMessage id="code_input_box.online_order_only" defaultMessage={`${getLocationDescription}`} />

      </li>

      <li>
        <FormattedMessage id="code_input_box.online_order_only" defaultMessage="Online Order on our website only." />
      </li>
      {!orderingOfferCode.orderingOffer?.orderingOfferBxgyDetail && (
      <li>
        <FormattedMessage
          id="code_input_box.offer_applies_to"
          defaultMessage="This offer applies to {offerType}."
          values={{ offerType: orderingOfferType(orderingOfferCode) }}
        />
      </li>
      )}
      {freeOfferType && (
        <li>
          <FormattedMessage
            id="code_input_box.offer_free_dish"
            defaultMessage="Get one {dish} for FREE{offerType}."
            values={{ dish: orderingOfferCode.orderingOffer.orderingOfferBxgyDetail.redeemableDishes[0].dish.name, offerType: freeOfferType === 'free_with_purchase' && ` when you spend ${formatCurrency(orderingOfferCode.orderingOfferMinimumOrderAmount, currency)} or more` }}
          />
        </li>
      )}
      {freeOfferType === 'free_without_purchase' && (
        <li>
          <FormattedMessage id="code_input_box.offer_no_additional_purchase" defaultMessage="No additional purchase necessary." />
        </li>
      )}
      {!freeOfferType && (
        <li>
          <FormattedMessage
            id="code_input_box.offer_type_detail"
            defaultMessage="This offer is for {offerType}"
            values={{ offerType: `${orderingOfferCode.orderingOffer.offerType === 'percentage_off_offer_type' || orderingOfferCode.orderingOffer.offerType === 'partial_order_percentage_off_offer_type' ?
              `${amount}%` : `${formatCurrency(amount, currency)}`} ${orderingOfferCode.orderingOffer.orderingOfferBxgyDetail ? 'off select items.' : 'off your order.'}` }}
          />
        </li>
      )}
      {!orderingOfferCode.orderingOffer?.orderingOfferBxgyDetail && (
        <li>
          <FormattedMessage
            id="code_input_box.offer_minimum_order_amount"
            defaultMessage="Minimum {minimumAmount} purchase necessary."
            values={{ minimumAmount: formatCurrency(orderingOfferCode.orderingOfferMinimumOrderAmount, currency) }}
          />
        </li>
      )}
      {orderingOfferCode.orderingOffer?.orderingOfferBxgyDetail && orderingOfferCode.orderingOffer.orderingOfferBxgyDetail.bxgyType === 'default' && (
        <React.Fragment>
          <li>
            <FormattedMessage
              id="code_input_box.bxgy_offer_get"
              defaultMessage="Buy {amount}: {applicable_dishes}"
              values={{ amount: orderingOfferCode.orderingOffer?.orderingOfferBxgyDetail.applicableQuantity, applicable_dishes: getQualifyingDishes('applicableDishes') }}
            />
          </li>
          <li>
            <FormattedMessage
              id="code_input_box.bxgy_offer_buy"
              defaultMessage="Get {amount}: {redeemable_dishes}"
              values={{ amount: orderingOfferCode.orderingOffer?.orderingOfferBxgyDetail.redeemableQuantity, redeemable_dishes: getQualifyingDishes('redeemableDishes') }}
            />
          </li>
        </React.Fragment>
      )}
      {!orderingOfferCode.orderingOfferSchedule && (
        <li>
          <FormattedMessage
            id="code_input_box.offer_expiration_date"
            defaultMessage="{expiration}"
            values={{ expiration: orderingOfferCode.orderingOfferExpirationDate ?
              `Expiration Date: ${formatDate(orderingOfferCode.orderingOfferExpirationDate)}` :
              'No Expiration Date' }}
          />
        </li>
      )}
      <li>
        <FormattedMessage
          id="code_input_box.offer_use_amount"
          defaultMessage="Usage: You can redeem this offer code {onetimeUse} and apply it {onetimeUsePerOrder} an order."
          values={{ onetimeUse: orderingOfferCode.orderingOfferIsOnetimeUse ?
            'once' :
            'multiple times',
          onetimeUsePerOrder: orderingOfferCode.orderingOfferIsOnetimeUsePerOrder ?
            'once in' :
            'multiple times to' }}
        />
      </li>
      {orderingOfferCode.orderingOffer.orderingOfferSchedule && (
        <React.Fragment>
          { getFormattedSchedule().map(string => (
            <li>
              <FormattedMessage
                id="code_input_box.schedule"
                defaultMessage="{schedule}"
                values={{ schedule: string }}
              />
            </li>
          )) }
        </React.Fragment>
      )}
    </ul>
  );
};

export default OfferDetailsModal;
