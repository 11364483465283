import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Avatar, Badge, Box, Typography, Icon, Button } from '@popmenu/common-ui';
import { CameraPlus } from '@popmenu/web-icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { createEvent } from '~/utils/eventable';
import { ProfileContext } from '../../../shared/ProfileContextProvider';

import BasicModal from '../../../admin/shared/BasicModal';
import { profileAvatarBadgeStyles } from '../profile.styles';
import { AH } from '../../shared/AccessibleHeading';

const useStyles = makeStyles(profileAvatarBadgeStyles);

const ProfileAvatar = () => {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const {
    activeUser,
  } = useContext(ProfileContext);
  const { spacing } = useTheme();
  const classes = useStyles();
  const intl = useIntl();

  const handleAvatarClick = () => {
    createEvent({
      eventableType: 'GuestProfile',
      eventLabel: 'profile | sidebar | upload_photo_icon',
      eventType: 'profile_sidebar_upload_photo_click',
    });
    setShowProfileModal(true);
  };

  return (
    <Box
      height={spacing(27.5)}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Button onClick={handleAvatarClick} aria-label={intl.formatMessage({ defaultMessage: 'Upload Avatar Photo', id: 'profile.avatar.upload_photo' })}>
        <Badge
          overlap="circular"
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          badgeContent={(
            <Box
              height={spacing(3)}
              width={spacing(3)}
              borderRadius={100}
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={classes.root}
            >
              <Icon icon={CameraPlus} className={classes.icon} />
            </Box>
          )}
        >
          <Avatar
            alt={activeUser.name}
            src={activeUser.avatarUrl}
            size="extra-large"
            className={classes.pseudoButton}
            data-cy={'avatar'}
          />
        </Badge>
      </Button>
      <AH typography variant="h6">
        {activeUser.name || 'Welcome Back'}
      </AH>
      <Typography variant="subtitle2" color="textSecondary">
        <FormattedMessage
          id="profile.vip_date"
          defaultMessage="VIP since {date}"
          values={{ date: activeUser.profileCreationDate }}
        />
      </Typography>
      {/* This is a temporary modal until photo upload feature is implemented. */}
      <BasicModal
        closeModal={() => setShowProfileModal(false)}
        show={showProfileModal}
        size="sm"
      >
        <Box>
          <AH typography variant="h5">
            <FormattedMessage id="profile.avatar_upload.title" defaultMessage="Feature Coming Soon!" />
          </AH>
        </Box>
        <Box marginY={2}>
          <Typography>
            <FormattedMessage id="profile.avatar_upload.message" defaultMessage="The ability to change your profile picture will be added in a future update." />
          </Typography>
        </Box>
      </BasicModal>
    </Box>
  );
};

export default ProfileAvatar;
