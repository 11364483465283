import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText } from '@popmenu/common-ui';
import { formatCurrency } from '../../../../utils/utils';
import { profileGiftCardsDetailsStyles } from '../../profile.styles';

const useStyles = makeStyles(profileGiftCardsDetailsStyles);

const GiftCardsDetails = ({ giftCard }) => {
  const classes = useStyles();

  return (
    <List disablePadding>
      <ListItem>
        <ListItemText>
          <FormattedMessage
            id="consumer.ordering.code"
            defaultMessage="Code: "
          />
          <FormattedMessage
            id="profile.profile_gift_card_code"
            defaultMessage="**** **** **** {last_four}"
            values={{ last_four: giftCard.lastFour }}
          />
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          <FormattedMessage
            id="consumer.ordering.original_balance"
            defaultMessage="Original Balance: "
          />
          <FormattedMessage
            id="profile.profile_currency"
            defaultMessage="{currency}"
            values={{ currency: formatCurrency(giftCard.originalAmount, undefined, { showDecimals: true, showSymbol: true }) }}
          />
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText classes={classes}>
          <FormattedMessage
            id="consumer.ordering.current_balance"
            defaultMessage="Current Balance: "
          />
          <FormattedMessage
            id="profile.profile_currency"
            defaultMessage="{currency}"
            values={{ currency: formatCurrency(giftCard.amount, undefined, { showDecimals: true, showSymbol: true }) }}
          />
        </ListItemText>
      </ListItem>
    </List>
  );
};

GiftCardsDetails.propTypes = {
  giftCard: PropTypes.object.isRequired,
};

export default GiftCardsDetails;
