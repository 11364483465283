import React from 'react';
import PropTypes from 'prop-types';

import { ListItemIcon } from '@popmenu/common-ui';
import { makeStyles } from '@material-ui/core/styles';

import { profileNavItemIconStyles } from '../profile.styles';

const useStyles = makeStyles(profileNavItemIconStyles);

const ProfileNavItemIcon = ({ icon, selected }) => {
  const classes = useStyles({
    color: selected ? 'primary' : 'common',
    variant: selected ? 'contrastText' : 'black',
  });

  return (
    <ListItemIcon className={classes.listItemIcon}>
      {React.createElement(icon, { classes })}
    </ListItemIcon>
  );
};

ProfileNavItemIcon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]).isRequired,
  selected: PropTypes.bool.isRequired,
};

export default ProfileNavItemIcon;
