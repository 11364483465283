import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@popmenu/common-ui';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { createEvent } from '~/utils/eventable';
import { formatCurrency } from '../../../../utils/currency';
import { withRestaurant } from '../../../../utils/withRestaurant';
import { setGiftCardId, setProfileEventTracking } from '../../../../shared/ConsumerActions';
import { closeProfileV2Modal } from '../../../../shared/ModalActions';

import { ProfileContext } from '../../../../shared/ProfileContextProvider';
import GiftCardsDetails from './GiftCardsDetails';
import BasicModal from '../../../../admin/shared/BasicModal';
import { profileCardsStyles } from '../../profile.styles';
import { AH } from '../../../shared/AccessibleHeading';

const useStyles = makeStyles(profileCardsStyles);

const GiftCardsCard = ({ giftCard }) => {
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    renderOrderCartUrl,
  } = useContext(ProfileContext);
  const profileEventTracking = useSelector(state => state.consumer.profileEventTracking);

  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="column"
        border={1}
        borderRadius="borderRadius"
        borderColor="gray.dark"
        justifyContent="center"
        alignItems="center"
        padding={1.5}
      >
        {giftCard.imageUploadedPhoto && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingBottom={1.5}
            overflow="hidden"
          >
            <img
              src={giftCard.imageUploadedPhoto.thumbnailUrl}
              alt={giftCard.imageUploadedPhoto.originalFilename}
              className={classes.cardImg}
            />
          </Box>
        )}
        <Box marginBottom={1}>
          <AH typography variant="h5" align="center">
            <FormattedMessage
              id="profile.profile_gift_card_code"
              defaultMessage="**** **** **** {last_four}"
              values={{ last_four: giftCard.lastFour }}
            />
          </AH>
        </Box>
        <Box fontWeight={600}>
          <Typography>
            <FormattedMessage
              id="profile.profile_gift_card_value"
              defaultMessage="Current Value: {value}"
              values={{ value: `${formatCurrency(giftCard.amount, undefined, { showDecimals: true, showSymbol: true })}` }}
            />
          </Typography>
        </Box>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          marginTop={2}
        >
          <Box width="50%" paddingRight={1}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => setShowDetailsModal(true)}
            >
              <FormattedMessage
                id="models.guest_profile.offer.see_details"
                defaultMessage="See Details"
              />
            </Button>
          </Box>
          <Box width="50%" paddingLeft={1}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                createEvent({
                  eventableType: 'GuestProfile',
                  eventLabel: 'profile | giftcards | redeem_now_button | redeem_now',
                  eventType: 'profile_giftcards_redeem_now_click',
                });
                dispatch(setGiftCardId(giftCard.id));
                if (!profileEventTracking.some(event => event === 'gift_card')) {
                  dispatch(setProfileEventTracking([...profileEventTracking, 'gift_card']));
                }
                dispatch(closeProfileV2Modal());
                history.push(renderOrderCartUrl());
              }}
            >
              <FormattedMessage
                id="models.guest_profile.offer.redeem_now"
                defaultMessage="Redeem Now"
              />
            </Button>
          </Box>
        </Box>
      </Box>
      <BasicModal
        closeModal={() => setShowDetailsModal(false)}
        show={showDetailsModal}
        size="sm"
        title="Gift Card Details"
      >
        <GiftCardsDetails
          giftCard={giftCard}
        />
      </BasicModal>
    </React.Fragment>
  );
};

GiftCardsCard.propTypes = {
  giftCard: PropTypes.object.isRequired,
};

export default withRestaurant(GiftCardsCard);
